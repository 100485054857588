import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tag, Button } from 'antd';
import { MinusOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';

export class TargetsUpdater extends Component {
  constructor(props) {
    super(props);
    this.state = {
      target_uuid: props.target.target_uuid,
      initial_target: props.target.target_month_default_value,
      current_target: props.target.target_month_default_value,
    };
  }

  updateCurrentTarget(new_target) {
    this.setState({ current_target: new_target });
  }

  async sendUpdateRequest(target_uuids, new_target) {
    const { getIdToken, updateTarget, active_filters } = this.props;
    const token = await getIdToken();
    const updateResult = await updateTarget(
      token,
      active_filters,
      target_uuids,
      new_target
    );
    if (updateResult) {
      this.setState({ initial_target: new_target });
    }
  }

  render() {
    const { target, update_loading } = this.props;

    if ('children' in target) {
      let total_targets = 0;
      target.children.map((single_target) => {
        total_targets += single_target.target_month_default_value;
        return null;
      });
      return <Tag color="#385bdc">{total_targets}</Tag>;
    }

    const tagColor = this.state.current_target > this.state.initial_target
      ? '#5ba65c'
      : this.state.current_target < this.state.initial_target
        ? '#c45c5c'
        : '#7a91e0';

    return [
      <Tag color={tagColor}>{this.state.current_target}</Tag>,
      <Button
        className="target-update-minus-button"
        disabled={
          this.state.current_target === 0
          || update_loading === target.target_uuid
        }
        icon={<MinusOutlined />}
        size="small"
        onClick={() => this.updateCurrentTarget(this.state.current_target - 1)}
      />,
      <Button
        icon={<PlusOutlined />}
        size="small"
        disabled={update_loading === target.target_uuid}
        onClick={() => this.updateCurrentTarget(this.state.current_target + 1)}
      />,
      this.state.initial_target !== this.state.current_target ? (
        <Button
          className="target-save-button"
          ghost
          loading={
            Array.isArray(update_loading)
            && update_loading.filter((item) => target.target_uuids.includes(item))
              .length > 0
          }
          onClick={() => this.sendUpdateRequest(
            target.target_uuids,
            this.state.current_target
          )}
          type="primary"
          icon={<SaveOutlined />}
          size="small"
        >
          Save
        </Button>
      ) : (
        ''
      ),
    ];
  }
}

TargetsUpdater.defaultProps = {
  currentFilter: {},
  renderButtons: null,
};

TargetsUpdater.propTypes = {
  t: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.object,
  renderButtons: PropTypes.func,
};

export default TargetsUpdater;

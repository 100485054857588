import React from 'react';
import {
  Row, Col, Button, Divider
} from 'antd';
import { AreaChartOutlined, DoubleLeftOutlined, LockOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import TargetsFilter from './TargetsFilter';
import TargetsTable from '../../Containers/TargetsTable';
import Config from '../../Config';

class Targets extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      targets: props.targets,
    };
  }

  onFilter(filter) {
    this.setState({
      filter,
      hasList: false,
      hasStructure: false,
    });
  }

  componentDidMount() {
    this.initTargetFilters();
    this.getAllTargets();
  }

  async getAllTargets() {
    const { getAllTargets, getIdToken, activeFilters } = this.props;
    const token = await getIdToken();
    await getAllTargets(token, activeFilters);
  }

  async initTargetFilters() {
    const { initTargetFilter } = this.props;
    const { getIdToken } = this.props;
    const token = await getIdToken();
    await initTargetFilter(token);
  }

  render() {
    const { overwrite_mode, toggleOverwriteMode, t } = this.props;

    return [
      <Row type="flex" justify="center" align="top">
        <Col span={4}>
          <h1>
            <strong>{t('common:target')}</strong>
          </h1>
          <Divider />
        </Col>
        <Col span={20} style={{ textAlign: 'right' }}>
          {overwrite_mode ? (
            <Button
              onClick={() => toggleOverwriteMode()}
              ghost
              className="overwrite-target-button"
              style={{ backgroundColor: '#fff' }}
              type="danger"
              icon={<DoubleLeftOutlined />}
            >
              {t('common:cancelEditing')}
            </Button>
          ) : (
            [
              <a
                href={Config.apiGateway.QS_DASHBOARD_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button icon={<AreaChartOutlined />}>
                  {t('common:viewTargetsReportingDaskboard')}
                </Button>
              </a>,
              <Button
                onClick={() => toggleOverwriteMode()}
                ghost
                className="overwrite-target-button"
                style={{ backgroundColor: '#fff' }}
                type="danger"
                icon={<LockOutlined />}
                >
                {t('common:editLockedTargets')}
              </Button>,
            ]
          )}
          <Divider />
        </Col>
      </Row>,
      <TargetsFilter
        filterOptions={this.props.targetFilters}
        setFilter={this.props.setFilter}
        isLoading={this.props.isLoading}
        activeFilters={this.props.activeFilters}
        getAllTargets={() => this.getAllTargets()}
      />,
      <TargetsTable />,
    ];
  }
}

export default withTranslation(['common', 'errors', 'maintenanceMode'])(Targets);

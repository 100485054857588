import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row, Select, Card } from 'antd';
import { SolutionOutlined, TeamOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';

export class TargetsFilter extends Component {
  generateSelect(select_config, with_offset) {
    const { field_name } = select_config;
    const {
      filterOptions,
      setFilter,
      activeFilters,
      getAllTargets,
      isLoading,
    } = this.props;
 
    const options = Object.keys(filterOptions[field_name]).sort((a, b) =>
      filterOptions[field_name][a].localeCompare(filterOptions[field_name][b]),
    );


    return (
      <Col md={4} xs={24} offset={+with_offset} className="search">
        {React.cloneElement(select_config.icon, { style: { color: '#1168e3', marginRight: 3 } })}
        <span className="team-label">
          {select_config.label}
          :
        </span>
        <Select
          label={select_config.label}
          loading={isLoading}
          defaultValue={activeFilters[field_name][0]}
          disabled={isLoading}
          placeholder={`Select a ${select_config.label}`}
          dropdownMatchSelectWidth={false}
          style={{ width: '100%' }}
          showSearch
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          onChange={(selectedOption) => {
            setFilter(field_name, selectedOption, activeFilters);
            getAllTargets();
          }}
          options={[
            { key: 'all', value: 'all', label: 'Select all' },
            ...options.map((opt) => ({ key: opt, value: opt, label: filterOptions[field_name][opt] })),
          ]}

        />
      </Col>
    );
  }

  render() {
    const { t } = this.props;

    const filter_select_config = [
      {
        label: t('common:team'),
        icon: <TeamOutlined />,
        field_name: 'team_uuid',
      },
      {
        label: t('common:teamMemberName'),
        icon: <UserOutlined />,
        field_name: 'team_member_uuid',
      },
      {
        label: t('common:teamLeaderName'),
        icon: <UserOutlined />,
        field_name: 'team_leader_uuid',
      },
      {
        label: t('common:task'),
        icon: <SolutionOutlined />,
        field_name: 'task_uuid',
      },
      {
        label: t('common:criticalRisk'),
        icon: <WarningOutlined />,
        field_name: 'critical_risk_uuid',
      },
    ];

    return (
      <Card style={{ marginTop: 10, marginBottom: 15 }}>
        <h3 className="filter-by-title">{t('common:filterBy')}</h3>
        <Row>
          {filter_select_config.map((select_config, index) => this.generateSelect(select_config, index !== 0))}
        </Row>
      </Card>
    );
  }
}

export default withTranslation(['common', 'errors', 'maintenanceMode'])(TargetsFilter);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tag, Button } from "antd";
import moment from "moment-timezone";
import { MinusOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";

const MIN_TARGET_VALUE = 0;

export class MonthlyTargetsOverwriter extends Component {
  constructor(props) {
    super(props);
    console.log(props.target)

    if (!('children' in props.target))
      return

    this.state = {
      initial_target: MIN_TARGET_VALUE,
      current_target: MIN_TARGET_VALUE,
    };
  }

  monthlyTargetDateObject(monthly_target) {
    let year_month_date_object = new Date();
    let parsed_month = parseInt(String(monthly_target.attributes.target_month).replace("0", "")) - 1

    year_month_date_object.setDate(1);
    year_month_date_object.setMonth(parsed_month);
    year_month_date_object.setYear(parseInt(monthly_target.attributes.target_year));

    return year_month_date_object
  }

  updateCurrentTarget(new_target) {
    this.setState({ current_target: new_target });
  }

  async sendUpdateRequest(target_uuids, new_target) {
    const { getIdToken, overwriteTarget } = this.props;
    const token = await getIdToken();
    const update_successful = await overwriteTarget(
      token,
      target_uuids,
      new_target
    );

    if (update_successful) {
      this.setState({ initial_target: new_target });
    }
  }

  render() {
    const { target, update_loading, overwrite_selected_month } = this.props;


      //if parent target
      if ("children" in target) {
        let total_targets = 0;

        target.children.map((single_target) => {
          if (Array.isArray(single_target.monthly_targets.data)) {
            let selected_monthly_target = single_target.monthly_targets.data.filter(
                (monthly_target) => {
                  let year_month_date_object = this.monthlyTargetDateObject(monthly_target)
                  return (
                      overwrite_selected_month ===
                      moment(year_month_date_object).format("YYYY-MM")
                  );
                }
            );

            if (selected_monthly_target.length > 0) total_targets += selected_monthly_target[0].attributes.target_value;
          }

          return null;
        });

        return (
            <Tag color={total_targets === 0 ? "#9d9d9d" : "#385bdc"}>
              {total_targets === 0 ? "-" : total_targets}
            </Tag>
        );
      }


      let targets_within_selected_month = target.monthly_targets.filter((monthly_target) => {
        let year_month_date_object = this.monthlyTargetDateObject(monthly_target)

        return (
            overwrite_selected_month ===
            moment(year_month_date_object).format("YYYY-MM")
        );
      });

    console.log("WITHIN")
    console.log(targets_within_selected_month)

    if (targets_within_selected_month.length === 0) {
      return <Tag color={"#9d9d9d"}>{" - "}</Tag>
    }

    if (this.state.initial_target !== targets_within_selected_month[0].attributes.target_value) {
      this.setState({
        current_target: targets_within_selected_month[0].attributes.target_value,
        initial_target: targets_within_selected_month[0].attributes.target_value
      })
    }

    let tagColor =
        this.state.current_target > this.state.initial_target
            ? "#5ba65c"
            : this.state.current_target < this.state.initial_target
                ? "#c45c5c"
                : "#7a91e0";

    return [
      <Tag color={tagColor}>{this.state.current_target}</Tag>,
      <Button
          icon={<MinusOutlined />}
          size={"small"}
          disabled={update_loading === targets_within_selected_month[0].id || this.state.current_target === MIN_TARGET_VALUE}
          onClick={() => this.updateCurrentTarget(this.state.current_target - 1)}
      />,
      <Button
        icon=<PlusOutlined />
        size={"small"}
        disabled={update_loading === targets_within_selected_month[0].id}
        onClick={() => this.updateCurrentTarget(this.state.current_target + 1)}
      />,
      this.state.initial_target !== this.state.current_target ? (
        <Button
          className={"target-save-button"}
          ghost={true}
          loading={
            Array.isArray(update_loading) &&
            update_loading.filter((item) =>
              [targets_within_selected_month[0].id].includes(item)
            ).length > 0
          }
          onClick={() =>
            this.sendUpdateRequest(
              [targets_within_selected_month[0].id],
              this.state.current_target
            )
          }
          type="danger"
          style={{ backgroundColor: "#fff !important" }}
          icon={<SaveOutlined />}
          size={"small"}
        >
          Overwrite
        </Button>
      ) : (
        ""
      ),
    ];
  }
}

MonthlyTargetsOverwriter.defaultProps = {
  currentFilter: {},
  renderButtons: null,
};

MonthlyTargetsOverwriter.propTypes = {
  t: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.object,
  renderButtons: PropTypes.func,
};

export default (MonthlyTargetsOverwriter);

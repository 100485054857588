import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Select, Table, Popover, Tag, Button
} from 'antd';
import {
  EnterOutlined,
  SolutionOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import TargetsUpdater from '../../../Containers/TargetsUpdater';
import MonthlyTargetsOverwriter from '../../../Containers/MonthlyTargetsOverwriter';
import Config from '../../../Config';

export class TargetsTable extends Component {
  async handlePaginationChange(page, size) {
    const { getAllTargets, getIdToken, activeFilters } = this.props;
    const token = await getIdToken();
    getAllTargets(token, { ...activeFilters, 'page.number': page, 'page.size': size });
  }

  render() {
    const {
      monthly_targets_date_from,
      setOverwriteSelectedMonth,
      overwrite_mode,
      overwrite_selected_month,
      isLoading,
      targets,
      risk_profile_access,
      pagination,
      history,
      t
    } = this.props;

    const today_by_client_timezone = moment().tz(Config.apiGateway.CLIENT_TIMEZONE).format('D');
    const target_column_header_month = today_by_client_timezone > 6 ? moment().add(1, 'months').format('MMM') : moment().format('MMM');
    const months_different_from_today = Math.ceil(moment(monthly_targets_date_from).diff(moment(), 'months', true));
    const monthly_targets_filter_options = [];

    const show_risk_profile = risk_profile_access && !overwrite_mode;

    // calculate months between earliest and latest target
    for (let i = Math.abs(months_different_from_today); i > 0; i--) {
      const moment_obj = moment().startOf('month').subtract(i, 'months');
      monthly_targets_filter_options.push(
        <Select.Option key={moment_obj.format('YYYY-MM')}>
          {moment_obj.format('MMMM')}
          {' '}
          <span style={{ color: '#b2b2b2', fontSize: '11px' }}>
            (
            {moment_obj.format('YYYY')}
            )
          </span>
        </Select.Option>
      );
    }

    monthly_targets_filter_options.push(
      <Select.Option
        key={moment().format('YYYY-MM')}
        value={moment().format('YYYY-MM')}
      >
        {moment().format('MMMM')}
        <span style={{ color: '#b2b2b2', fontSize: '11px' }}>
          (
          {moment().format('YYYY')}
          )
        </span>
      </Select.Option>
    );

    const overwrite_month_select = [
      <span style={{ float: 'left' }}>{t('common:supervisorTarget')}</span>,
      <Select
        size="small"
        style={{
          float: 'right', position: 'absolute', right: 0, top: '15px'
        }}
        onChange={setOverwriteSelectedMonth}
        dropdownMatchSelectWidth={false}
        initialValue={overwrite_selected_month}
        defaultValue={overwrite_selected_month}
      >
        {monthly_targets_filter_options.reverse()}
      </Select>,
    ];

    const table_column_definition = [
      {
        title: t('common:team'),
        dataIndex: 'team_name',
        key: 'team_name',
        width: '15%',
        render: (text, record, index) => (!('children' in record) ? (
          <span>
            <EnterOutlined style={{ transform: 'rotateY(180deg) scale(1)' }} />
            {' '}
            {text}
          </span>
        ) : (
          text
        )),
      },
      {
        title: t('common:teamLeader'),
        dataIndex: 'team_leader_name',
        key: 'team_leader_name',
        width: '15%',
        render: (text, record) => (
          <span>
            <UserOutlined />
            {' '}
            {text || record.team_leader_uuid}
          </span>
        ),
      },
      {
        title: t('common:task'),
        dataIndex: 'task',
        key: 'task',
        width: '15%',
        render: (text, record) => (record.is_task ? <span>{text}</span> : null),
      },
      {
        title: t('common:criticalRisk'),
        dataIndex: 'critical_risk',
        key: 'critical_risk',
        width: '15%',
        render: (text, record) => (record.is_task ? (
          <Popover
            content={Object.keys(record.critical_risks).map((risk) => (
              <Tag color="orange">{risk}</Tag>
            ))}
          >
            <Tag color="orange">
              <WarningOutlined />
              {' '}
              {Object.keys(record.critical_risks).length}
              {' '}
              {t('common:risks')}
            </Tag>
          </Popover>
        ) : 'children' in record ? (
          <Tag color="orange">
            {' '}
            {text}
          </Tag>
        ) : null),
      },
      {
        title: t('common:teamMember'),
        dataIndex: 'team_member_name',
        key: 'team_member_name',
        width: '15%',
        render: (text, record, index) => ('children' in record ? (
          <Tag color="gray">
            <UserOutlined />
            {' '}
            {record.children.length}
            {' '}
            {t('common:teamMembers')}
          </Tag>
        ) : (
          <span>
            <UserOutlined />
            {' '}
            {text}
          </span>
        )),
      },
      {
        title: overwrite_mode ? (
          overwrite_month_select
        ) : (
          <Popover
            placement="topRight"
            content={t('common:supervisorTargetTooltip')}
          >
            <span>
              {t('common:supervisorTarget')}
              {' '}
              <Tag color="#108ee9">{target_column_header_month}</Tag>
            </span>
          </Popover>
        ),
        dataIndex: 'target_month_default_value',
        key: 'target_month_default_value',
        render: (text, record) => (overwrite_mode ? (
          <MonthlyTargetsOverwriter target={record} />
        ) : (
          <TargetsUpdater target={record} />
        )),
        width: !overwrite_mode ? '15%' : '20%',
      },
      {
        title: show_risk_profile ? t('common:riskProfile') : '',
        key: 'risk_profile',
        width: '8%',
        render: (text, record, index) => (show_risk_profile ? (
          'children' in record ? (
            <div>
              <Button onClick={(e) => {
                e.preventDefault();
                history.push(`/teams/${record.team_uuid}/risk-profile?referrer=targets`);
              }}
              >
                <SolutionOutlined />
              </Button>
            </div>
          ) : (
            ''
          )
        ) : (
          ''
        )),
      },
    ];

    return (
      <Table
        className={['responsive-table', overwrite_mode ? 'overwrite' : '']}
        dataSource={[...targets].sort((a, b) => a.team_name.localeCompare(b.team_name))}
        columns={table_column_definition}
        rowKey="key"
        loading={isLoading}
        pagination={{
          position: 'bottom',
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
          onChange: (page, pageSize) => this.handlePaginationChange(page, pageSize),
          onShowSizeChange: (page, pageSize) => this.handlePaginationChange(page, pageSize),
          ...pagination
        }}
        expandRowByClick
        expandable={{
          rowExpandable: true,
        }}
      />
    );
  }
}

TargetsTable.defaultProps = {
  targets: [],
  overwrite_mode: false,
};

TargetsTable.propTypes = {
  targets: PropTypes.array,
  risk_profile_access: PropTypes.bool.isRequired,
};

export default withRouter(withTranslation(['common', 'errors', 'maintenanceMode'])(TargetsTable));

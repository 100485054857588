import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import commonEn from 'componentlibrary/dist/translations/en.json';
import commonFr from 'componentlibrary/dist/translations/fr.json';
import commonEs from 'componentlibrary/dist/translations/es.json';
import commonMn from 'componentlibrary/dist/translations/mn.json';
import commonPt from 'componentlibrary/dist/translations/pt.json';
import commonRu from 'componentlibrary/dist/translations/ru.json';
import commonIs from 'componentlibrary/dist/translations/is.json';
import commonNb from 'componentlibrary/dist/translations/nb.json';
import commonZh from 'componentlibrary/dist/translations/zh.json';
import commonId from 'componentlibrary/dist/translations/id.json';
import commonTpi from 'componentlibrary/dist/translations/tpi.json';
import commonNl from 'componentlibrary/dist/translations/nl.json';
import commonDe from 'componentlibrary/dist/translations/de.json';
import commonIt from 'componentlibrary/dist/translations/it.json';
import commonSr from 'componentlibrary/dist/translations/sr.json';
import commonFi from 'componentlibrary/dist/translations/fi.json';
import commonJa from 'componentlibrary/dist/translations/ja.json';
import commonPl from 'componentlibrary/dist/translations/pl.json';
import commonHi from 'componentlibrary/dist/translations/hi.json';
import commonUk from 'componentlibrary/dist/translations/uk.json';
import commonTr from 'componentlibrary/dist/translations/tr.json';
import appEn from './en.json'; // english
import appFr from './fr.json'; // french
import appEs from './es.json'; // spanish
import appMn from './mn.json'; // mongolian
import appPt from './pt.json'; // portuguese (brazil)
import appRu from './ru.json'; // russian
import appIs from './is.json'; // icelandic
import appNb from './nb.json'; // norwegian (bokmål)
import appZh from './zh.json'; // chinese (simplified)
import appId from './id.json'; // indonesian
import appTpi from './tpi.json'; // tok Pisin
import appNl from './nl.json'; // dutch
import appDe from './de.json'; // german
import appIt from './it.json'; // italian
import appSr from './sr.json'; // serbian
import appFi from './fi.json'; // finnish
import appJa from './ja.json'; // japanese
import appPl from './pl.json'; // polish
import appHi from './hi.json'; // hindi
import appUk from './uk.json'; // ukrainian
import appTr from './tr.json'; // turkish

const en = { ...commonEn, ...appEn };
const fr = { ...commonFr, ...appFr };
const es = { ...commonEs, ...appEs };
const mn = { ...commonMn, ...appMn };
const pt = { ...commonPt, ...appPt };
const ru = { ...commonRu, ...appRu };
const is = { ...commonIs, ...appIs };
const nb = { ...commonNb, ...appNb };
const zh = { ...commonZh, ...appZh };
const id = { ...commonId, ...appId };
const tpi = { ...commonTpi, ...appTpi };
const nl = { ...commonNl, ...appNl };
const de = { ...commonDe, ...appDe };
const it = { ...commonIt, ...appIt };
const sr = { ...commonSr, ...appSr };
const fi = { ...commonFi, ...appFi };
const ja = { ...commonJa, ...appJa };
const pl = { ...commonPl, ...appPl };
const hi = { ...commonHi, ...appHi };
const uk = { ...commonUk, ...appUk };
const tr = { ...commonTr, ...appTr };

export const SELECT_LOCALE = 'i18nextLng';

function initialiseI18nModule() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: {
        default: ['en']
      },
      debug: true,
      interpolation: {
        escapeValue: false // not needed for react!!
      },
      detection: {
        // order and from where user language should be detected
        order: ['localStorage', 'navigator', 'querystring', 'cookie', 'htmlTag', 'path', 'subdomain'],
        lookupLocalStorage: SELECT_LOCALE,
        caches: ['localStorage']
      },
      // lng: 'es', // If you want to enforce a default language
      // react i18next special options (optional)
      react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
      },
      resources: {
        // add all resource languages here
        en,
        fr,
        es,
        mn,
        pt,
        ru,
        is,
        nb,
        zh,
        id,
        tpi,
        nl,
        de,
        it,
        sr,
        fi,
        ja,
        pl,
        hi,
        uk,
        tr
      }
    });

  i18n.on('languageChanged', (lang) => {
    document.documentElement.lang = lang;
  });
}

export function clearLocaleSelection() {
  localStorage.removeItem(SELECT_LOCALE);
  initialiseI18nModule();
}

initialiseI18nModule();

export default i18n;
